$(document).ready(function () {
  // hide all banner
  let banner = document.getElementsByClassName("banner")
  for (let i = 0; i < banner.length; i++) {
    banner[i].style.display = "none";
  }
  // show first banner
  if (document.getElementById("bb1")) {
    document.getElementById("bb1").style.display = "block";

    // dot under banner
    let list_dot = document.getElementById("list-dot")
    let list_dot_count = 0
    if (list_dot) {
      list_dot_count = list_dot.childElementCount;
    }
    // if have dot under banner do this code
    for (let i = 1; i <= list_dot_count; i++) {
      // set click event for all dot
      document.getElementById("b" + i).addEventListener("click", function () {
        // when dot clicked remove all active class
        let a_dot = document.getElementsByClassName("a-dot")
        for (let i = 0; i < a_dot.length; i++) {
          a_dot[i].classList.remove("active");
        }
        // set active class to dot that get click
        document.getElementById("b" + i).classList.add("active")
        // hide all banner
        let banner = document.getElementsByClassName("banner")
        for (let i = 0; i < banner.length; i++) {
          banner[i].style.display = "none";
        }
        // show banner is number same of dot that click
        document.getElementById("bb" + i).style.display = "block";
      });
    }
  }

  $('.owl-carousel').owlCarousel({
    loop: false,
    margin: 30,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  });
});